import { type RouteRecordRaw } from 'vue-router'

const setting: RouteRecordRaw = {
  path: '/setting',
  name: 'settings',
  component: () => import('@/views/setting/page-setting.vue'),
  children: [
    {
      path: 'profile',
      name: 'setting profile',
      component: () => import('@/views/setting/page-setting-profile.vue'),
    },
    {
      path: 'coach',
      name: 'setting coach',
      component: () => import('@/views/setting/page-setting-coach.vue'),
    },
    {
      path: 'email',
      name: 'setting email',
      component: () => import('@/views/setting/email/page-setting-email.vue'),
    },
    {
      path: 'password',
      name: 'setting password',
      component: () => import('@/views/setting/page-setting-password.vue'),
    },
    {
      path: 'schedule-presets',
      name: 'setting schedule presets',
      component: () => import('@/views/setting/schedule-preset/page-list.vue'),
    },
  ],
}

export default setting
