<script setup lang="ts">
  import { computed, ref } from 'vue'

  const showPassword = ref(false)
  const type = computed(() => showPassword.value ? 'text' : 'password')
</script>
<template>
  <div class="relative">
    <app-input :type="type" v-bind="$attrs" />
    <button
      class="absolute right-0 top-5 p-2 text-gray-500"
      tabindex="100"
      type="button"
      @click="showPassword = !showPassword">
      <fa-icon v-if="showPassword" icon="i-far-eye-slash" />
      <fa-icon v-else icon="i-far-eye" />
    </button>
  </div>
</template>


<style lang="postcss" scoped>
  :deep() input[type="text"],
  :deep() input[type="password"] {
    padding-right: 2.5rem;
  }
</style>
