<script setup lang="ts">
  import { computed } from 'vue'
  // source https://flowbite.com/docs/forms/toggle/
  const props = defineProps<{
    modelValue: boolean,
    disabled?: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', data: boolean): void,
  }>()

  const value = computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    },
  })
</script>

<template>
  <label class="relative inline-flex items-center cursor-pointer">
    <input v-model="value" type="checkbox" class="sr-only peer">
    <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary-600"></div>
  </label>
</template>
