<script setup lang="ts">
  withDefaults(defineProps<{
    modelValue?: string,
    placeholder?: string,
  }>(), {
    modelValue: 'test',
    placeholder: 'Search...',
  })

  const emit = defineEmits<{
    (e: 'submit'): void,
    (e: 'update:modelValue', data?: string): void,
  }>()
</script>

<template>
  <form class="relative" @submit.prevent="emit('submit')">
    <input
      :value="modelValue"
      class="w-full bg-gray-100 py-3 pl-12 text-xs font-medium placeholder-gray-800"
      :placeholder="placeholder"
      @input="emit('update:modelValue', ($event.target as HTMLInputElement).value ?? '')">
    <div class="absolute top-0 h-full flex items-center px-4">
      <fa-icon icon="i-far-magnifying-glass" />
    </div>
  </form>
</template>
