import {
  addMinutes,
  format,
  formatDistanceToNowStrict,
} from 'date-fns'

const replaceTimeCustom = (date: Date, str: string) => {
  // date-fns default is 1-12 for AM and PM
  // this is to convert 1-12 AM to 0-11 for AM, but keep 1-12 PM
  if (format(date, 'a') === 'PM') {
    return str
  }

  return str.replace(format(date, 'hh:mm'), format(date, 'HH:mm'))
}

const formatter = (dt: string | number | Date, fmt: string) => {
  return replaceTimeCustom(new Date(dt), format(new Date(dt), fmt))
}

export default {
  date: formatter,
  dateSchedule: (dt: string | number | Date) => {
    if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
      return '-'
    }

    return formatter(new Date(dt), 'EEE, dd MMM yyyy, hh:mm') + ' - '
      + formatter(addMinutes(new Date(dt), 20), 'hh:mm aaa')
  },
  dateScheduleExtended: (dt: string | number | Date) => {
    return formatter(new Date(dt), 'EEE, dd MMM yyyy, hh:mm aaa') + ' - '
    + formatter(addMinutes(new Date(dt), 40), 'hh:mm aaa')
  },
  dateDiffHuman: (dt: string | number | Date) => {
    return formatDistanceToNowStrict(new Date(dt), { addSuffix: true })
  },
}
