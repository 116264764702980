import { useDebounceFn } from '@vueuse/core'
import { nanoid } from 'nanoid'
import byte from './fmt/byte'
import DateFormatter from './fmt/date'
import duration from './fmt/duration'
import NumberFormatter from './fmt/number'
import PhoneFormatter from './fmt/phone'
import StringFormatter from './fmt/string'


export const randomId = nanoid

/**
 * Since unhandled rejection error is quite annoying when developer
 * doesn't need the return value, the promise will NOT be rejected
 * if the function is canceled by default. You need to specify
 * the option rejectOnCancel: true to capture the rejection.
 */
// const debouncedRequest = useDebounceFn(() => 'response', 1000, { rejectOnCancel: true })
export const debounce = useDebounceFn

// https://github.com/MaxLeiter/sortablejs-vue3#vuex
export const handleEndEventSortable = <T>(array: T[], from: number, to: number) => {
  const data = [...array]

  const item = data.splice(from, 1)[0]
  data.splice(to, 0, item)

  array = data
}

export const fmt = {
  ...DateFormatter,
  ...NumberFormatter,
  ...StringFormatter,
  phone: PhoneFormatter,
  kebab (str: string) {
    return str
      .replace(/[^\w\s]+/gm, '') // remove non alphanumeric
      .replace(/\s+/gm, '-') // replace multiple spaces to single dash
      .toLowerCase()
  },
  byte,
  duration,
}
