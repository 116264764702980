<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string,
    modelValue?: string,
    type?: string,
    label?: string,
    placeholder?: string,
    required?: boolean,
    error?: string[],
    maxLength?: number,
    rows?: number,
    disabled?: boolean,
    autocomplete?: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
  }>()

  const value = computed({
    set: val => {
      emit('update:modelValue', val ?? '')
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)
</script>

<template>
  <div class="relative my-2 py-1">
    <label v-if="label" :for="inputId" class="form-label">
      {{ label }}
    </label>
    <slot>
      <div class="flex items-end">
        <textarea
          v-if="type === 'textarea'"
          :id="inputId"
          class="form-input"
          :class="{'!pr-8': maxLength}"
          :disabled="disabled"
          :value="value"
          :placeholder="placeholder"
          :rows="rows ?? 3"
          :required="required"
          :maxlength="maxLength"
          @input="onInput"></textarea>
        <input
          v-else
          :id="inputId"
          class="form-input"
          :class="{'!pr-8': maxLength}"
          :disabled="disabled"
          :autocomplete="autocomplete ?? 'off'"
          :type="type"
          :value="value"
          :placeholder="placeholder"
          :required="required"
          :maxlength="maxLength"
          @input="onInput">

        <div class="absolute right-0">
          <span v-if="maxLength" class="bg-white px-1 text-2xs">
            {{ fmt.number(maxLength - (value?.length ?? 0)) }}
          </span>
        </div>
      </div>
    </slot>
    <app-error-msg :message="error" />
  </div>
</template>

<style lang="postcss" scoped>
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .form-input {
    @apply w-full py-1 text-sm font-medium placeholder-transparent focus:outline-none;
    @apply border-b border-gray-300 text-gray-800;
  }
  .form-label {
    @apply text-xs text-gray-500;
  }

  .has-error {
  input, textarea {
    @apply border-b border-b-red-400;
  }
  label {
    @apply text-red-400;
  }
}
</style>
