import { type RouteRecordRaw } from 'vue-router'

const appointment: RouteRecordRaw = {
  path: '/appointment',
  component: () => import('@/layouts/app/page-app.vue'),
  children: [
    {
      path: '',
      component: () => import('@/views/appointment/list/page-list.vue'),
      name: 'appointments',
    },
    {
      path: ':id',
      component: () => import('@/views/appointment/show/page-show.vue'),
      name: 'appointment show',
      props: true,
    },
    {
      path: 'created/:id',
      name: 'appointment created',
      props: true,
      component: () => import('@/views/appointment/page-created.vue'),
    },
  ],
}

export default appointment
