import { type RouteRecordRaw } from 'vue-router'

const programOrder: RouteRecordRaw = {
  path: '/program-order',
  component: () => import('@/layouts/app/page-app.vue'),
  children: [
    {
      path: ':id',
      component: () => import('@/views/program-order/page-show.vue'),
      props: true,
      name: 'program order show',
    },
    {
      path: ':orderId/domain/:domainId',
      name: 'program order book session',
      props: true,
      component: () => import('@/views/program-order/book-session/page-index.vue'),
    },
  ],
}

export default programOrder
